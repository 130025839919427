import React from "react"
import Layout from "../components/layout"
import { Seo } from "../components/Seo"

const cookie = () => {
  return (
    <Layout>
      <div class="ui-body-container products">
        <h1 class="body-title product-title">Cookie Policy</h1>
        <div class="spacing-divider--extra-small"></div>
        <div class="ui-body__text-image products">
          <div class="ui-body__text-image--left product-content">
            <h1 class="body-title">Cookie Policy for IA</h1>

            <div className="spacing-divider--extra-small"></div>

            <h1 className="body-title">What Are Cookies</h1>

            <p>
              As is common practice with almost all professional websites this
              site uses cookies, which are tiny files that are downloaded to
              your computer, to improve your experience. This page describes
              what information they gather, how we use it and why we sometimes
              need to store these cookies. We will also share how you can
              prevent these cookies from being stored however this may downgrade
              or 'break' certain elements of the site's functionality.
            </p>

            <div className="spacing-divider--extra-small"></div>

            <h1 className="body-title">How We Use Cookies</h1>

            <p>
              We only use cookies to stop abuse of our sites and infrastructure
              currently. For example, bot detection.
            </p>

            <div className="spacing-divider--extra-small"></div>

            <h1 className="body-title">Disabling Cookies</h1>

            <p>
              You can prevent the setting of cookies by adjusting the settings
              on your browser (see your browser Help for how to do this). Be
              aware that disabling cookies will affect the functionality of this
              and many other websites that you visit. Disabling cookies will
              usually result in also disabling certain functionality and
              features of this site. Therefore it is recommended that you do not
              disable cookies.
            </p>

            <div className="spacing-divider--extra-small"></div>

            <h1 className="body-title">The Cookies We Set</h1>

            <p>
              We are commited to being privacy first here at IA so we only set
              one cookie for bot management and detection, you only have to be
              worried if you're not human.
            </p>

            <div className="spacing-divider--extra-small"></div>

            <h1 className="body-title">More Information</h1>

            <p>
              Hopefully, that has clarified things for you and as was previously
              mentioned if there is something that you aren't sure whether you
              need or not it's usually safer to leave cookies enabled in case it
              does interact with one of the features you use on our site.
            </p>

            <p>
              However, if you are still looking for more information then you
              can contact us through one of our preferred contact methods:
            </p>

            <p>Email: admin@i-a.cloud</p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default cookie
export const Head = () => <Seo />
